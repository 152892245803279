import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const Flower = forwardRef(({ color = 'currentColor', size = 24, ...rest }, ref) => (
  <svg
    ref={ref}
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    fillRule="evenodd"
    clipRule="evenodd"
    stroke={color}
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    {...rest}
  >
    <path d="M11.5857 1.13439C11.8955 0.955202 12.2774 0.955202 12.5872 1.13439C13.4001 1.60463 14.1015 2.24573 14.6426 3.00909C15.0155 2.82697 15.4063 2.68014 15.8099 2.57165C16.1555 2.47874 16.5244 2.57758 16.7772 2.83084C18.3608 4.41714 19.0817 6.78463 18.4586 9.10979C18.0552 10.6155 17.153 11.8574 15.9788 12.7022C15.1245 13.3437 14.1065 13.7797 13 13.936V15.743C13.1118 15.6099 13.2303 15.4806 13.3554 15.3554C14.9267 13.7842 17.1521 13.2678 19.1509 13.8016C19.4966 13.894 19.7667 14.164 19.859 14.5098C20.3928 16.5085 19.8764 18.7339 18.3052 20.3052C16.8613 21.749 14.8652 22.3021 13 21.9681V22C13 22.5523 12.5523 23 12 23C11.4477 23 11 22.5523 11 22V13.9092C9.96055 13.7335 9.00408 13.3103 8.19458 12.7025C7.0201 11.8577 6.11773 10.6157 5.71422 9.10979C5.09119 6.78464 5.81203 4.41715 7.39567 2.83085C7.6485 2.57759 8.0174 2.47874 8.363 2.57165C8.76653 2.68014 9.15738 2.82698 9.53031 3.00909C10.0714 2.24573 10.7728 1.60463 11.5857 1.13439ZM13.6221 20.0385C14.7887 20.167 15.9985 19.7835 16.891 18.891C17.7835 17.9985 18.167 16.7887 18.0385 15.6221C16.8719 15.4936 15.6621 15.8771 14.7696 16.7696C13.8771 17.6621 13.4936 18.8719 13.6221 20.0385ZM12.0864 3.20191C11.5354 3.62804 11.0859 4.17911 10.7806 4.81189C10.6524 5.07754 10.4135 5.27306 10.1278 5.34617C9.84206 5.41928 9.53862 5.36252 9.29862 5.19107C9.02327 4.99435 8.7265 4.82896 8.41438 4.69829C7.59811 5.77013 7.27115 7.19293 7.64607 8.59215C7.9266 9.63911 8.55228 10.4985 9.36921 11.0838L9.3887 11.0981C10.1407 11.6649 11.0733 12 12.0864 12C13.0997 12 14.0325 11.6648 14.7845 11.0978L14.804 11.0835C15.6208 10.4982 16.2463 9.63896 16.5268 8.59215C16.9017 7.19293 16.5747 5.77013 15.7585 4.69829C15.4464 4.82895 15.1496 4.99435 14.8742 5.19106C14.6343 5.36251 14.3308 5.41927 14.0451 5.34616C13.7593 5.27305 13.5204 5.07753 13.3923 4.81188C13.087 4.17911 12.6375 3.62804 12.0864 3.20191Z" />
  </svg>
));

Flower.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

Flower.displayName = 'Flower';

export default Flower;

import React, { useRef, useState, useLayoutEffect, useEffect, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';
import cn from 'classnames';
import { getCoordinatesForPosition, findScrollContainer, getIsVisibleInsideScroll } from './utils';

import './Tooltip.scss';

const useIsomorphicLayoutEffect = typeof window !== 'undefined' ? useLayoutEffect : useEffect;

const ACTIONS = {
  CLICK: 'click',
  HOVER: 'hover'
};

const Tooltip = React.forwardRef(
  (
    {
      content,
      text,
      className,
      children,
      position = 'left',
      mouseEnterDelay = 10,
      mouseLeaveDelay = 10,
      showOnScroll = false,
      action = ACTIONS.HOVER,
      rootId = 'tooltip-root',
      isInverted,
      disabled = false,
      onClick
    },
    ref
  ) => {
    const [isVisible, setIsVisible] = useState(false);
    const triggerRef = useRef(null);
    const contentRef = useRef(null);
    const timeOut = useRef(null);

    const scrollRef = useRef(null);

    useIsomorphicLayoutEffect(() => {
      scrollRef.current = findScrollContainer(triggerRef.current);
    }, []);

    const getRootContainer = () => {
      const el = document.getElementById(rootId);
      if (el) {
        return el;
      }
      const tooltip = document.createElement('div');
      tooltip.setAttribute('id', rootId);
      document.body.appendChild(tooltip);
      return tooltip;
    };

    const handleShow = () => {
      if (onClick && typeof onClick === 'function') {
        onClick();
      }

      clearTimeout(timeOut.current);
      timeOut.current = setTimeout(() => setIsVisible(true), mouseEnterDelay);
    };

    const handleSetInvisible = () => {
      setIsVisible(false);
      const el = document.getElementById(rootId);
      if (el) {
        document.body.removeChild(el);
      }
    };

    const handleHide = () => {
      clearTimeout(timeOut.current);
      timeOut.current = setTimeout(handleSetInvisible, mouseLeaveDelay);
    };

    const handleClickOutside = (e) => {
      if (
        !e.composedPath().includes(triggerRef.current) &&
        !e.composedPath().includes(contentRef.current)
      ) {
        handleHide();
      }
    };

    const setPosition = (e) => {
      if (!isVisible) {
        return;
      }
      // show error as one of ref is undefined);
      if (!triggerRef?.current || !contentRef?.current) {
        return;
      }

      const trigger = triggerRef?.current?.getBoundingClientRect();
      const content = contentRef?.current?.getBoundingClientRect();
      const scroll = scrollRef?.current?.getBoundingClientRect();

      if (e?.type === 'scroll') {
        const isVisibleInScroll = getIsVisibleInsideScroll(content, scroll);

        if (!isVisibleInScroll || !showOnScroll) {
          return handleSetInvisible();
        }
      }

      const cords = getCoordinatesForPosition(trigger, content, position, scroll);
      contentRef.current.style.top = `${cords.top + window.scrollY}px`;
      contentRef.current.style.left = `${cords.left + window.scrollX}px`;
    };

    useIsomorphicLayoutEffect(() => {
      if (isVisible) {
        setPosition();

        if (action === ACTIONS.CLICK) {
          document.addEventListener('mousedown', handleClickOutside);
        }

        scrollRef?.current?.addEventListener('scroll', setPosition);
        window?.addEventListener('resize', setPosition);
      }
      return () => {
        if (!isVisible) {
          return;
        }

        if (action === ACTIONS.CLICK) {
          document.removeEventListener('mousedown', handleClickOutside);
        }
        scrollRef?.current?.removeEventListener('scroll', setPosition);
        window?.removeEventListener('resize', setPosition);
      };
    }, [isVisible]);

    const childElement = Array.isArray(children) ? children.find((el) => el.type) : children;

    const childActionProps =
      action === ACTIONS.HOVER
        ? { onMouseEnter: handleShow, onMouseLeave: handleHide }
        : { onClick: handleShow };

    const setContentRef = (r) => {
      contentRef.current = r;

      if (ref?.current) {
        ref.current = r;
      }
    };

    const handleSetRef = (ref) => {
      triggerRef.current = ref;

      if (!childElement.ref) {
        return;
      }

      if (typeof childElement.ref === 'function') {
        childElement.ref(ref);
      } else {
        childElement.ref.current = ref;
      }
    };

    const ClonedChild = useMemo(
      () =>
        React.cloneElement(childElement, {
          ref: handleSetRef,
          ...childActionProps
        }),
      [childElement, handleSetRef, childActionProps]
    );

    const handleDrag = useCallback(() => {
      setIsVisible(false);
    }, [setIsVisible]);

    useEffect(() => {
      if (isVisible && triggerRef?.current) {
        const { current } = triggerRef;

        current?.addEventListener('drag', handleDrag);

        return () => current?.removeEventListener('drag', handleDrag);
      }
    }, [isVisible, triggerRef, handleDrag]);

    const portal = createPortal(
      <div
        ref={setContentRef}
        className={cn('tooltip-content', { tooltip__inverted: isInverted }, className)}
      >
        {content || text}
      </div>,
      getRootContainer()
    );

    return (
      <>
        {ClonedChild}
        {isVisible && !disabled && portal}
      </>
    );
  }
);

Tooltip.propTypes = {
  // toDo: remove after all apps
  text: PropTypes.string, // deprecated value
  content: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  position: PropTypes.oneOf([
    'top',
    'top right',
    'top left',
    'bottom',
    'bottom right',
    'bottom left',
    'left',
    'left top',
    'left bottom',
    'right',
    'right top',
    'right bottom'
  ]),
  action: PropTypes.oneOf([ACTIONS.HOVER, ACTIONS.CLICK]),
  showOnScroll: PropTypes.bool,
  mouseEnterDelay: PropTypes.number,
  mouseLeaveDelay: PropTypes.number,
  isInverted: PropTypes.bool,
  rootId: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func
};

export default Tooltip;

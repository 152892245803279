import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const SwarmTriangle = forwardRef(({ size = 24, ...rest }, ref) => (
  <svg
    ref={ref}
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    {...rest}
  >
    <path
      d="M10.7661 5C11.5359 3.66667 13.4604 3.66667 14.2302 5L20.7254 16.25C21.4952 17.5833 20.533 19.25 18.9934 19.25H6.00299C4.46338 19.25 3.50113 17.5833 4.27093 16.25L10.7661 5Z"
      fill="#F22A2A"
    />
    <rect x="12.1062" y="9.16675" width="0.787879" height="2.36364" rx="0.393939" fill="white" />
    <rect x="12.1062" y="15.4697" width="0.787879" height="2.36364" rx="0.393939" fill="white" />
    <rect
      x="9.15747"
      y="10.7141"
      width="0.787879"
      height="2.36364"
      rx="0.393939"
      transform="rotate(-45 9.15747 10.7141)"
      fill="white"
    />
    <rect
      x="8.16675"
      y="13.8938"
      width="0.787879"
      height="2.36364"
      rx="0.39394"
      transform="rotate(-90 8.16675 13.8938)"
      fill="white"
    />
    <rect
      x="13.6145"
      y="15.1714"
      width="0.787878"
      height="2.36364"
      rx="0.393939"
      transform="rotate(-45 13.6145 15.1714)"
      fill="white"
    />
    <rect
      x="14.4697"
      y="13.8938"
      width="0.787879"
      height="2.36364"
      rx="0.39394"
      transform="rotate(-90 14.4697 13.8938)"
      fill="white"
    />
    <rect
      x="9.7146"
      y="16.843"
      width="0.787879"
      height="2.36364"
      rx="0.393939"
      transform="rotate(-135 9.7146 16.843)"
      fill="white"
    />
    <rect
      x="14.1714"
      y="12.3857"
      width="0.787879"
      height="2.36364"
      rx="0.393939"
      transform="rotate(-135 14.1714 12.3857)"
      fill="white"
    />
  </svg>
));

SwarmTriangle.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

SwarmTriangle.displayName = 'SwarmTriangle';

export default SwarmTriangle;

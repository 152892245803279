import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const MarkerAlertQueen = forwardRef(({ color = 'currentColor', size = 24, ...rest }, ref) => (
  <svg
    ref={ref}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 12C0 5.37258 5.37258 0 12 0V0C18.6274 0 24 5.37258 24 12V12C24 18.6274 18.6274 24 12 24V24C5.37258 24 0 18.6274 0 12V12Z"
      fill="white"
    />
    <circle cx="12" cy="12" r="10" fill="#F22A2A" />
    <path
      d="M7.12384 15.1165V16.0568C7.12384 16.2845 7.30841 16.469 7.53613 16.469H16.4639C16.6916 16.469 16.8762 16.2845 16.8762 16.0568V15.1165H7.12384Z"
      fill="white"
    />
    <path
      d="M19 9.87584C19 9.1413 18.4024 8.54369 17.6678 8.54369C16.9333 8.54369 16.3358 9.1413 16.3358 9.87584C16.3358 10.229 16.4741 10.5502 16.6993 10.7889C16.2988 11.2368 15.7174 11.5196 15.0707 11.5196C14.024 11.5196 13.1473 10.7796 12.9351 9.79541C13.1801 9.5537 13.3322 9.2182 13.3322 8.84778C13.3322 8.11324 12.7345 7.51562 12 7.51562C11.2655 7.51562 10.6678 8.11324 10.6678 8.84778C10.6678 9.2182 10.8201 9.5537 11.0649 9.79541C10.8527 10.7796 9.97603 11.5196 8.92928 11.5196C8.28264 11.5196 7.70116 11.2368 7.30072 10.7889C7.52588 10.5502 7.66431 10.229 7.66431 9.87584C7.66431 9.1413 7.0667 8.54369 6.33215 8.54369C5.59761 8.54369 5 9.1413 5 9.87584C5 10.5124 5.44893 11.0459 6.04675 11.1768C6.16499 12.1526 6.39346 13.3416 6.83459 14.292H17.1654C17.6065 13.3416 17.835 12.1526 17.9532 11.1768C18.5511 11.0457 19 10.5124 19 9.87584Z"
      fill="white"
    />
  </svg>
));

MarkerAlertQueen.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

MarkerAlertQueen.displayName = 'MarkerAlertQueen';

export default MarkerAlertQueen;

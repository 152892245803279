import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const RightPanelToggle = forwardRef(({ color = 'currentColor', size = 24, ...rest }, ref) => (
  <svg
    ref={ref}
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    stroke={color}
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    {...rest}
  >
    <rect x="16" y="4" width="4" height="16" fill="white" stroke="white" />
    <rect width="9" height="3" transform="matrix(-1 0 0 1 13 4)" />
    <rect width="9" height="10" transform="matrix(-1 0 0 1 13 10)" />
  </svg>
));

RightPanelToggle.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

RightPanelToggle.displayName = 'RightPanelToggle';

export default RightPanelToggle;

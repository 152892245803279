import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const Openned = forwardRef(({ color = 'currentColor', size = 24, ...rest }, ref) => (
  <svg
    ref={ref}
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    stroke={color}
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    {...rest}
  >
    <g clipPath="url(#clip0)">
      <path d="M18.4852 7.75735L22.7279 12L18.4852 16.2426" />
      <path d="M5.51477 16.2426L1.27213 12L5.51477 7.75735" />
      <path d="M22.7279 12H13.8285" />
      <path d="M1.27205 12H10.1715" />
    </g>
    <defs>
      <clipPath id="clip0">
        <path d="M0 0H24V24H0V0Z" fill="white" />
      </clipPath>
    </defs>
  </svg>
));

Openned.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

Openned.displayName = 'Openned';

export default Openned;

import PropTypes from 'prop-types';

export const SelectProps = {
  error: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
  value: PropTypes.string,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  isMulti: PropTypes.bool,
  size: PropTypes.oneOf(['normal', 'small']),
  info: PropTypes.string,
  components: PropTypes.shape(),
  addNewOption: PropTypes.shape({
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired
  }),
  disabled: PropTypes.bool
};
export const AsyncProps = {
  ...SelectProps,
  loadOptions: PropTypes.func.isRequired
};

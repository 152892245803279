import React, { memo } from 'react';
import { connect } from 'react-redux';
import Icon from '@beewise/icon';
import { isLoading } from '../utils/helpers';

export default (actionTypes, CustomLoaderComponent) => (ComposingComponent) => {
  const MergedLoaderOrContent = (props) => {
    const { isLoadingActive, ...restProps } = props;

    if (CustomLoaderComponent) {
      return isLoadingActive ? <CustomLoaderComponent /> : <ComposingComponent {...restProps} />;
    }

    return (
      <div className="preloader-wrapper">
        {isLoadingActive && (
          <div className="preloader">
            <Icon type="loading" className="beewise-preloader" />
          </div>
        )}
        <ComposingComponent {...restProps} />
      </div>
    );
  };

  const mapStateToProps = (state) => isLoading(state, actionTypes);

  return connect(mapStateToProps)(memo(MergedLoaderOrContent));
};

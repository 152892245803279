import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const Preloader = forwardRef(({ color = '#FEBA12', size = 160, ...rest }, ref) => (
  <svg
    ref={ref}
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 160 160"
    fill="none"
    stroke={color}
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    textRendering="geometricPrecision"
    shapeRendering="geometricPrecision"
    {...rest}
    className="preloader-big"
  >
    <g id="evcxubi3z5ul2">
      <path
        id="evcxubi3z5ul3"
        d="M78.846200,49.086900L98.692400,83.461500L78.846200,117.836000L39.153800,117.836000L19.307600,83.461500L39.153800,49.086900L78.846200,49.086900Z"
        strokeWidth="9.192980"
      />
      <path
        id="evcxubi3z5ul4"
        d="M67.971200,67.923000L76.942400,83.461500L67.971200,99.000100L50.028800,99.000100L41.057600,83.461500L50.028800,67.923000L67.971200,67.923000Z"
        strokeWidth="6.894740"
      />
    </g>
    <g id="evcxubi3z5ul5">
      <path
        id="evcxubi3z5ul6"
        d="M130.856000,26.848300L141.558000,45.384600L130.856000,63.921000L109.452000,63.921000L98.749900,45.384600L109.452000,26.848300L130.856000,26.848300Z"
        strokeWidth="6.894740"
      />
      <path
        id="evcxubi3z5ul7"
        d="M129.385000,45.384600L124.769000,53.378700L115.538000,53.378700L110.923000,45.384600L115.538000,37.390500L124.769000,37.390500L129.385000,45.384600Z"
        fill="rgb(254,186,18)"
        strokeWidth="1"
      />
    </g>
    <path
      id="evcxubi3z5ul8"
      d="M129.385000,122.692000L120.731000,137.681000L103.423000,137.681000L94.769200,122.692000L103.423000,107.703000L120.731000,107.703000L129.385000,122.692000Z"
      fill="rgb(254,186,18)"
      strokeWidth="1"
    />
  </svg>
));

Preloader.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

Preloader.displayName = 'Preloader';

export default Preloader;

import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const MessageRead = forwardRef(({ color = '#222222', size = 14, ...rest }, ref) => (
  <svg
    ref={ref}
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 14 14"
    fill="none"
  >
    <path
      d="M13.66 5.33554C13.66 4.85535 13.4133 4.43518 13.0333 4.20175L6.99998 0.666992L0.966646 4.20175C0.586646 4.43518 0.333313 4.85535 0.333313 5.33554V12.0049C0.333313 12.7385 0.933313 13.3388 1.66665 13.3388H12.3333C13.0666 13.3388 13.6666 12.7385 13.6666 12.0049L13.66 5.33554ZM6.99998 8.67022L1.49331 5.22883L6.99998 2.00086L12.5066 5.22883L6.99998 8.67022Z"
      fill={color}
    />
  </svg>
));

MessageRead.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

MessageRead.displayName = 'MessageRead';

export default MessageRead;

export const TOAST_TYPE = {
    SUCCESS: 'toast-success',
    ERROR: 'toast-error',
    MESSAGE: 'toast-message',
    WARNING: 'toast-warning',
};

export const DEFAULT_NUMBER_FORMAT_UNITS = ['K', 'M', 'B'];

export const BEES_SAVED_PER_DAY = 41;

export const MAX_DAYS_TO_COUNT = 60;

export const DEFAULT_QUALITY_OF_FRAMES = 8;

export const MIN_DISPLAY_DAYS = 6;

// <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
//   <path d="M12.3333 0.667969H1.66665C0.933313 0.667969 0.33998 1.26821 0.33998 2.00184L0.333313 10.0051C0.333313 10.7387 0.933313 11.3389 1.66665 11.3389H12.3333C13.0666 11.3389 13.6666 10.7387 13.6666 10.0051V2.00184C13.6666 1.26821 13.0666 0.667969 12.3333 0.667969ZM12.3333 3.33571L6.99998 6.67039L1.66665 3.33571V2.00184L6.99998 5.33652L12.3333 2.00184V3.33571Z" fill="black"/>
// </svg>
import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const MessageUnread = forwardRef(({ color = '#222222', size = 14, ...rest }, ref) => (
  <svg
    ref={ref}
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 14 12"
    fill="none"
  >
    <path
      d="M12.3333 0.667969H1.66665C0.933313 0.667969 0.33998 1.26821 0.33998 2.00184L0.333313 10.0051C0.333313 10.7387 0.933313 11.3389 1.66665 11.3389H12.3333C13.0666 11.3389 13.6666 10.7387 13.6666 10.0051V2.00184C13.6666 1.26821 13.0666 0.667969 12.3333 0.667969ZM12.3333 3.33571L6.99998 6.67039L1.66665 3.33571V2.00184L6.99998 5.33652L12.3333 2.00184V3.33571Z"
      fill={color}
    />
  </svg>
));

MessageUnread.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

MessageUnread.displayName = 'MessageUnread';

export default MessageUnread;

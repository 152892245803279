import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const RedCircleIcon = forwardRef(({ color = 'currentColor', size, ...rest }, ref) => (
  <svg
    ref={ref}
    xmlns="http://www.w3.org/2000/svg"
    width="640"
    height="480"
    fill="none"
    fillRule="evenodd"
    clipRule="evenodd"
    stroke={color}
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    {...rest}
  >
    <defs>
      <linearGradient id="linearGradient1702">
        <stop id="stop1703" offset="0" stopOpacity="0.690722" stopColor="#ffffff" />
        <stop id="stop1704" offset="1" stopOpacity="0" stopColor="#ffffff" />
      </linearGradient>
      <linearGradient id="linearGradient1697">
        <stop id="stop1698" offset="0" stopColor="#fff" />
        <stop id="stop1699" offset="1" stopColor="#fff" />
      </linearGradient>
      <linearGradient
        id="linearGradient1077"
        x1="0.491802"
        x2="0.491802"
        xlinkHref="#linearGradient1697"
        y1="0.077665"
        y2="0.933375"
      />
      <linearGradient
        id="linearGradient1078"
        x1="0.478621"
        x2="0.482604"
        xlinkHref="#linearGradient1702"
        y1="-0.037714"
        y2="1.321203"
      />
    </defs>
    <g>
      <path
        d="m382.368164,193.390503c0.045868,105.189728 -85.213928,190.487274 -190.403671,190.487274c-105.18972,0 -190.449802,-85.297546 -190.403673,-190.487274c-0.046129,-105.18972 85.213953,-190.486983 190.403673,-190.486983c105.189743,0 190.449539,85.297263 190.403671,190.486983z"
        id="path1070"
        strokeDashoffset="0"
        strokeMiterlimit="4"
        strokeLinejoin="round"
        strokeLinecap="round"
        strokeWidth="1.364453"
        stroke="url(#linearGradient1077)"
        fillRule="nonzero"
        fill="#F22A2A"
      />
      <path
        d="m330.278198,143.236725c44.367188,-0.966797 -12.400146,-123.152874 -135.681015,-123.802784c-134.561523,-0.68712 -162.095943,110.961353 -140.719452,112.997211c0,0 36.864166,5.062622 71.939575,14.856079c56.472656,15.930817 125.549149,-13.069244 152.768753,-17.2836c30.342468,-4.697876 47.373413,15.392426 51.692139,13.233093z"
        id="path1713"
        strokeWidth="0.25000000pt"
        fillRule="evenodd"
        fill="url(#linearGradient1078)"
        opacity="0.9"
      />
    </g>
  </svg>
));

RedCircleIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

RedCircleIcon.displayName = 'RedCircleIcon';

export default RedCircleIcon;

import React, { forwardRef } from 'react';

const ScrollMore = forwardRef((props, ref) => (
  <svg
    ref={ref}
    width="49"
    height="52"
    viewBox="0 0 49 52"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x="8.52197" y="2.16675" width="31.9565" height="47.6667" rx="15.9783" fill="white" />
    <rect x="10.6523" y="4.33325" width="27.6957" height="43.3333" rx="13.8478" fill="#F22A2A" />
    <rect x="12.7827" y="6.5" width="23.4348" height="39" rx="11.7174" fill="white" />
    <ellipse cx="24.4999" cy="16.25" rx="3.19565" ry="3.25" fill="#F22A2A" />
    <path
      d="M18.7957 23.8333L24.5 29.19L30.2042 23.8333L31.9565 25.4824L24.5 32.4999L17.0435 25.4824L18.7957 23.8333Z"
      fill="#F22A2A"
    />
    <path
      d="M18.7957 32.5L24.5 37.8567L30.2042 32.5L31.9565 34.1491L24.5 41.1667L17.0435 34.1491L18.7957 32.5Z"
      fill="#F22A2A"
    />
  </svg>
));

ScrollMore.displayName = 'ScrollMore';

export default ScrollMore;

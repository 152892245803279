import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const RadioUncheck = forwardRef(({ color = '#e6e6e6', size = 24, ...rest }, ref) => (
  <svg
    ref={ref}
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="#e6e6e6"
    stroke={color}
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    {...rest}
  >
    <circle cx="12" cy="12" r="10" />
  </svg>
));

RadioUncheck.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

RadioUncheck.displayName = 'RadioUncheck';

export default RadioUncheck;

import React, { useEffect, useState, useCallback, useRef } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { loading, arrayOfObjectsShallowEqual, usePermission } from '@beewise/react-utils';
import { toJpeg } from 'html-to-image';
import { updateMapBounds, zoomInToBhomes } from 'components/reusables/Map/components/Map/utils';
import RanchApproval from 'components/reusables/RightPanel/RanchApproval';
import { useParams } from 'react-router-dom';
import { Map } from 'components/reusables/Map';
import GeneralInfoWithReports from './components/GeneralInfoWithReports';

import {
    FETCH_RANCHES,
    FETCH_FRAMES_PER_HIVE,
    FETCH_BEE_ACTIVITIES_OVER_TIME,
    FETCH_HIVE_DEVELOPMENT,
    FETCH_RANCH_WITH_BLOCKS,
} from './actionTypes';
import {
    fetchRanchApproval,
    fetchRanchWithBlocks,
    fetchRanches,
    selectCurrentRanchById,
    setRanchApproval,
} from './actions';
import { getRanches, getSelectedRanch, getSelectedRanchApproval } from './selectors';
import './GrowerView.scss';

const GrowerView = () => {
    const ref = useRef(null);
    const { id } = useParams();
    const isFarmManager = usePermission('isFarmManager', 'general');
    const isRanchManager = usePermission('isRanchManager', 'general');
    const isGrower = isFarmManager || isRanchManager;
    const dispatch = useDispatch();
    const ranches = useSelector(getRanches, arrayOfObjectsShallowEqual);
    const selectedRanch = useSelector(getSelectedRanch, shallowEqual);
    const [map, setMap] = useState(null);
    const selectedRanchApproval = useSelector(getSelectedRanchApproval, shallowEqual);

    const handleMapCentering = useCallback(item => zoomInToBhomes(item, map), [map]);

    const exportImage = useCallback(async () => {
        if (!ref.current) {
            return;
        }

        try {
            const filter = node => {
                const exclusionClasses = ['remove-from-jpeg'];
                return !exclusionClasses.some(classname => node.classList?.contains(classname));
            };
            const dataUrl = await toJpeg(ref.current, { quality: 0.9, cacheBust: true, filter });
            const link = document.createElement('a');

            link.href = dataUrl;
            link.download = 'grower-report.jpeg';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error('Error exporting image:', error);
        }
    }, [ref]);

    useEffect(() => {
        id ? dispatch(fetchRanchWithBlocks(id)) : dispatch(fetchRanches());
    }, [dispatch, id]);

    useEffect(() => {
        if (!selectedRanch) {
            return;
        }

        selectedRanch?.locations && updateMapBounds(map, selectedRanch.locations);
        handleMapCentering(selectedRanch);
    }, [handleMapCentering, map, selectedRanch]);

    useEffect(() => {
        if (!selectedRanch && ranches?.length) {
            dispatch(selectCurrentRanchById(ranches[0].id));
        }
    }, [dispatch, ranches, selectedRanch]);

    useEffect(() => {
        selectedRanch?.id && dispatch(fetchRanchApproval(selectedRanch?.id));
    }, [dispatch, selectedRanch]);

    if (!ranches.length) {
        return;
    }

    return (
        <>
            {selectedRanchApproval && !!Object.keys(selectedRanchApproval).length && (
                <RanchApproval
                    selectedRanchApproval={selectedRanchApproval}
                    className="approval-wrapper"
                    setRanchApprovalHandler={setRanchApproval}
                />
            )}
            <section className="grower-view" ref={ref}>
                <GeneralInfoWithReports
                    ranches={ranches}
                    selectedRanch={selectedRanch}
                    exportImage={exportImage}
                    isGrower={isGrower}
                />
                <Map onMapReady={setMap} getCurrentRanchSelector={getSelectedRanch} isGrower={isGrower} />
            </section>
        </>
    );
};

export default loading([
    FETCH_RANCHES.default,
    FETCH_FRAMES_PER_HIVE.default,
    FETCH_BEE_ACTIVITIES_OVER_TIME.default,
    FETCH_HIVE_DEVELOPMENT.default,
    FETCH_RANCH_WITH_BLOCKS.default,
])(GrowerView);

import constants from '@beewise/constants';
import { getContractHiveActionWithLayout } from './contractHive';

const isArrayNonEmpty = (arr) => Array.isArray(arr) && arr.length;
const prepareFrames = ({ id, place, status, frameId }) => ({
  id,
  place,
  status,
  frameId
});
/**
 * Checks if the moved frames data is valid.
 * @param {Object} param0 - Object containing movedFrames.
 * @param {Array} param0.frames - Array of whole bhome frames before movement.
 * @param {Array} param0.selectedFrames - Array of frames that been selected as hive.
 * @param {Array} param0.movedFrames - Array of moved frames.
 * @returns {boolean} True if frames, selectedFrames, movedFrames are arrays and have elements,
 *                    and if selectedFrames and movedFrames have same length, false otherwise.
 */
const isFramesDataValid = ({ frames, selectedFrames, movedFrames }) =>
  isArrayNonEmpty(frames) &&
  isArrayNonEmpty(selectedFrames) &&
  isArrayNonEmpty(movedFrames) &&
  selectedFrames.length === movedFrames.length;

/**
 * Handles the movement of frames based on provided data.
 * @param {Object} param0 - Object containing movedFrames, stations, and settings.
 * @param {Array} param0.frames - Array of whole bhome frames before movement.
 * @param {Array} param0.selectedFrames - Array of frames that been selected as hive.
 * @param {Array} param0.movedFrames - Array of frames that have been moved within param0.selectedFrames.
 * @param {Object} param0.stations - Stations involved in the hive operation.
 * @param {Object} param0.settings - Settings for the hive operation.
 * @returns {Object} An object representing the hive action sequences and new frames layout, or throws an error if data is invalid.
 */
const moveFramesCalculatorV4 = ({ frames, selectedFrames, movedFrames, stations, settings }) => {
  if (!isFramesDataValid({ frames, selectedFrames, movedFrames })) {
    throw new Error('Provided frames data is not valid');
  }

  const preparedSelectedFrames = structuredClone(selectedFrames);

  const preparedMovedFrames = movedFrames.map(
    ({ id, place, type, status, rfid, frameId, initialHiveIndex }) => ({
      id,
      place,
      type,
      rfid,
      status,
      frameId,
      initialHiveIndex
    })
  );

  const preparedFrames = frames.map(prepareFrames);

  const partitionIndex = preparedMovedFrames.findIndex(
    ({ type }) => type === constants.FRAME_TYPES.PARTITION
  );

  let movedFramesData;

  if (partitionIndex > 0) {
    movedFramesData = getContractHiveActionWithLayout({
      movedFrames: preparedMovedFrames,
      settings,
      stations,
      partitionIndex
    });
  }

  const { contractHiveAction, newHiveLayout } = movedFramesData;

  const changedHiveStartIndex = preparedFrames.findIndex(
    (frame) => frame.id === preparedSelectedFrames[0].id
  );

  preparedFrames.splice(
    changedHiveStartIndex,
    preparedSelectedFrames.length,
    ...newHiveLayout.map(prepareFrames)
  );

  return {
    contractHiveAction,
    newFramesLayout: preparedFrames.map((frame, index) => ({ ...frame, id: index }))
  };
};

export default moveFramesCalculatorV4;

import React, { useCallback, useEffect, useMemo, useState, useRef, memo } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import GoogleMapReact from '@beewise/google-maps';
import { arrayOfObjectsShallowEqual } from '@beewise/react-utils';
import SearchBox from 'components/reusables/Map/components/SearchBox';
import constants from 'appConstants';
import { getFilteredContentData, getRanches } from 'components/views/GrowerView/selectors';
import useAppSettings from 'utils/useAppSettings';
import renderMapAdditionalMarkers from '../MapAdditionalMarkers';
import { drawMarkers, updateMapBounds } from './utils';
import './Map.scss';

const DEFAULT_CENTER = { lat: 42.6, lng: -37.47 };
const DEFAULT_ZOOM = 4;

const {
    MAP: { API_KEY, DEFAULT_CURSOR_URL },
} = constants;

const Map = ({ onMapReady, isSearchboxShown, isMapMovable, getCurrentRanchSelector, isGrower }) => {
    const dispatch = useDispatch();
    const mapTypeIdListener = useRef(null);
    const [currentMarkers, setCurrentMarkers] = useState([]);
    const [polyShapes, setPolyShapes] = useState([]);
    const [options, setOptions] = useState({});
    const [mapTypeId, setMapTypeId] = useState('hybrid');
    const [map, setMap] = useState();
    const isBhomeView = options?.zoom > 11;
    const selectedRanch = useSelector(getCurrentRanchSelector, shallowEqual);
    const ranches = useSelector(getRanches, arrayOfObjectsShallowEqual);
    const ranchContentData = useSelector(getFilteredContentData, arrayOfObjectsShallowEqual);
    const appSettings = useAppSettings();
    const locations = useMemo(
        () => selectedRanch?.locations?.map(location => ({ ...location, ranchName: selectedRanch?.name })),
        [selectedRanch]
    );

    const createPolygonForRanch = useCallback((ranch, map) => {
        const polygon = new window.google.maps.Polygon({
            paths: ranch.blocks.map(({ polygon }) => polygon),
            strokeColor: '#feba12',
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: 'transparent',
        });

        polygon.setMap(map);
        polygon.ranchId = ranch.id;

        return polygon;
    }, []);

    const removePolygon = useCallback(polygon => {
        polygon.setMap(null);
    }, []);

    const handleMapChange = ({ center, zoom, bounds }) =>
        setOptions({
            center,
            zoom,
            bounds,
        });

    const onGoogleApiLoaded = useCallback(
        ({ map, maps }) => {
            onMapReady(map);
            mapTypeIdListener.current = maps.event.addListener(map, 'maptypeid_changed', () =>
                setMapTypeId(map.getMapTypeId())
            );
            setMap(map);
            updateMapBounds(map, selectedRanch?.locations || currentMarkers);
        },
        [setMap, currentMarkers, onMapReady, selectedRanch]
    );

    const drawnMarkers = useMemo(
        () =>
            drawMarkers(currentMarkers, selectedRanch, ranchContentData, appSettings, selectedRanch?.quality, isGrower),
        [appSettings, currentMarkers, isGrower, ranchContentData, selectedRanch]
    );

    useEffect(() => {
        if (!ranches.length) {
            return;
        }

        if (isBhomeView && !polyShapes.length) {
            const polygons = ranches.reduce((acc, ranch) => {
                const polygons = ranch.blocks.map(({ polygon }) => polygon);
                if (polygons && map) {
                    acc.push(createPolygonForRanch(ranch, map, dispatch));
                }
                return acc;
            }, []);
            setPolyShapes(polygons);
        } else if (!isBhomeView && polyShapes.length) {
            polyShapes.forEach(polygon => removePolygon(polygon));
            setPolyShapes([]);
        }

        return () => {
            polyShapes.forEach(polygon => {
                polygon.setMap(null);
            });
        };
    }, [createPolygonForRanch, dispatch, isBhomeView, map, polyShapes, ranches, removePolygon]);

    useEffect(() => {
        setCurrentMarkers(isBhomeView ? locations : ranches);
    }, [locations, ranches, isBhomeView]);

    // return back to the same markers after edit
    useEffect(() => {
        if (selectedRanch?.id) {
            setCurrentMarkers(locations);
            updateMapBounds(map, selectedRanch.locations);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRanch?.id, locations, map, selectedRanch?.locations]);

    useEffect(
        () => () => {
            if (mapTypeIdListener.current) {
                window.google?.maps?.event?.removeListener(mapTypeIdListener.current);
            }
        },
        []
    );

    return (
        <div
            className={cx(`map-wrapper grower-map ${map && `map-zoom-${map?.zoom}`}`, {
                [mapTypeId]: !!mapTypeId,
            })}
            id="map"
        >
            <GoogleMapReact
                apiKey={API_KEY}
                defaultCenter={DEFAULT_CENTER}
                defaultZoom={DEFAULT_ZOOM}
                onGoogleApiLoaded={onGoogleApiLoaded}
                // required to switch between bhomeview and ranch view
                onChange={handleMapChange}
                options={{
                    draggable: isMapMovable,
                    draggableCursor: DEFAULT_CURSOR_URL,
                    mapTypeId,
                    fullscreenControl: false,
                    zoomControl: false,
                    minZoom: 1,
                    scaleControl: true,
                }}
            >
                {drawnMarkers}
                {map?.zoom > 14 && renderMapAdditionalMarkers({ ranches })}
            </GoogleMapReact>
            {isSearchboxShown && (
                <div className="map-controls">
                    <SearchBox map={map} placeholder="Search" />
                </div>
            )}
        </div>
    );
};

Map.propTypes = {
    onMapReady: PropTypes.func,
    isSearchboxShown: PropTypes.bool,
    isMapMovable: PropTypes.bool,
    getCurrentRanchSelector: PropTypes.func.isRequired,
    isGrower: PropTypes.bool,
};

Map.defaultProps = {
    isMapMovable: true,
};

export default memo(Map);

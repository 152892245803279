import { CREATE_RANCH, UPDATE_RANCH, FETCH_RANCH } from '../actionTypes';

export const createRanch = (body, resolver, rejector) => ({
    type: 'FETCH',
    params: {
        method: 'POST',
        type: CREATE_RANCH.default,
        url: '/ranches/',
        body,
        resolver,
        rejector,
        toastText: 'Ranch successfully created',
    },
});

export const updateRanch = id => (body, resolver, rejector) => ({
    type: 'FETCH',
    params: {
        method: 'PATCH',
        type: UPDATE_RANCH.default,
        url: `/ranches/${id}`,
        body,
        resolver,
        toastText: 'Ranch successfully updated',
        rejector,
    },
});

export const fetchRanch = (id, resolver) => ({
    type: 'FETCH',
    params: {
        method: 'GET',
        type: FETCH_RANCH.default,
        url: `/ranches/${id}`,
        resolver,
    },
});

import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './CheckboxField.scss';

const CheckboxField = ({
  name,
  label,
  checked,
  labelClass,
  required,
  className,
  onChange,
  disabled,
  value
}) => (
  <fieldset className={cn(className, 'checkbox-root')}>
    <span className="checkbox-container">
      <input
        disabled={disabled}
        type="checkbox"
        name={name}
        // remove checked prop, require now to keep compatability with old semantic code
        checked={checked || value}
        className="checkbox-input"
        onChange={onChange}
      />
    </span>
    <label htmlFor={name} className={cn('checkbox-label', labelClass)}>
      {label}
      {required && ' *'}
    </label>
  </fieldset>
);
CheckboxField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  checked: PropTypes.bool,
  labelClass: PropTypes.string,
  required: PropTypes.bool,
  className: PropTypes.string,
  onChange: PropTypes.func
};

export default CheckboxField;

import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const BellNotification = forwardRef(({ color = 'currentColor', size = 32, ...rest }, ref) => (
  <svg
    ref={ref}
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 32 32"
    fill="none"
    stroke={color}
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    {...rest}
  >
    <path d="M18 16C18 14.4087 17.3679 12.8826 16.2426 11.7574C15.1174 10.6321 13.5913 10 12 10C10.4087 10 8.88258 10.6321 7.75736 11.7574C6.63214 12.8826 6 14.4087 6 16C6 23 3 25 3 25H21C21 25 18 23 18 16Z" />
    <path d="M13.73 29C13.5542 29.3031 13.3018 29.5547 12.9982 29.7295C12.6946 29.9044 12.3504 29.9965 12 29.9965C11.6496 29.9965 11.3054 29.9044 11.0018 29.7295C10.6982 29.5547 10.4458 29.3031 10.27 29" />
    <circle cx="22" cy="10" r="5" fill="#0075FF" />
  </svg>
));

BellNotification.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

BellNotification.displayName = 'BellNotification';

export default BellNotification;

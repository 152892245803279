import React, { forwardRef } from 'react';

const VerticalDots = forwardRef(({ color = 'currentColor', size = 24, ...rest }, ref) => (
  <svg
    width={size}
    stroke={color}
    height={size}
    ref={ref}
    viewBox="0 0 6 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="2" height="2" fill="#828282" />
    <rect x="4" width="2" height="2" fill="#828282" />
    <rect y="12" width="2" height="2" fill="#828282" />
    <rect x="4" y="12" width="2" height="2" fill="#828282" />
    <rect y="16" width="2" height="2" fill="#828282" />
    <rect x="4" y="16" width="2" height="2" fill="#828282" />
    <rect y="8" width="2" height="2" fill="#828282" />
    <rect x="4" y="8" width="2" height="2" fill="#828282" />
    <rect y="4" width="2" height="2" fill="#828282" />
    <rect x="4" y="4" width="2" height="2" fill="#828282" />
  </svg>
));

export default VerticalDots;

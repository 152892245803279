import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { faEllipsisV } from '@fortawesome/pro-regular-svg-icons';
import IconButton from '@beewise/icon-button';
import Tooltip from '@beewise/tooltip';
import './Menu.scss';

const Menu = forwardRef(({ options = [], position = 'bottom left', customComponent }, ref) => (
  <Tooltip
    action="click"
    isInverted
    showOnScroll
    position={position}
    rootId="menu-root"
    className="menu"
    ref={ref}
    content={options.map(({ label, onClick, isDisabled, tooltip }, index) => {
      const menuItem = (
        <div
          role="button"
          className={`menu-item ${isDisabled ? 'disabled' : ''}`}
          onClick={!isDisabled ? onClick : undefined}
          key={`${label}-${index}`}
        >
          {label}
        </div>
      );

      return tooltip ? (
        <Tooltip key={`${label}-${index}`} content={tooltip} action="hover" position="left">
          {menuItem}
        </Tooltip>
      ) : (
        menuItem
      );
    })}
  >
    {customComponent || <IconButton icon={faEllipsisV} className="icon" />}
  </Tooltip>
));

Menu.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({})),
  position: PropTypes.oneOf([
    'top',
    'top right',
    'top left',
    'bottom',
    'bottom right',
    'bottom left',
    'left',
    'left top',
    'left bottom',
    'right',
    'right top',
    'right bottom'
  ]),
  customComponent: PropTypes.node
};

export default Menu;

import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const EmptyMarkerDelete = forwardRef(({ color = 'currentColor', size = 24, ...rest }, ref) => (
  <svg
    ref={ref}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 12C0 5.37258 5.37258 0 12 0V0C18.6274 0 24 5.37258 24 12V12C24 18.6274 18.6274 24 12 24V24C5.37258 24 0 18.6274 0 12V12Z"
      fill="white"
    />
    <circle cx="12" cy="12" r="10" fill="#222222" />
    <path
      d="M16.6667 8.27333L15.7267 7.33333L12 11.06L8.27333 7.33333L7.33333 8.27333L11.06 12L7.33333 15.7267L8.27333 16.6667L12 12.94L15.7267 16.6667L16.6667 15.7267L12.94 12L16.6667 8.27333Z"
      fill="white"
    />
  </svg>
));

EmptyMarkerDelete.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

EmptyMarkerDelete.displayName = 'EmptyMarkerDelete';

export default EmptyMarkerDelete;

import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const HexagonMedium = forwardRef(({ color = '#FEBA12', size = 24, ...rest }, ref) => (
  <svg
    ref={ref}
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 52 50.5"
    fill="none"
    stroke={color}
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    {...rest}
  >
    <path
      d="M36.8558 6.84826L47.5578 25.3846L36.8558 43.921L15.4519 43.921L4.74991 25.3846L15.4519 6.84826L36.8558 6.84826Z"
      strokeWidth="6.89474"
    />
    <path
      d="M35.3846 25.3846L30.7692 33.3787L21.5385 33.3787L16.9231 25.3846L21.5385 17.3905L30.7692 17.3905L35.3846 25.3846Z"
      fill="#FEBA12"
    />
  </svg>
));

HexagonMedium.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

HexagonMedium.displayName = 'HexagonMedium';

export default HexagonMedium;

import React, { forwardRef } from 'react';

const OpenInNew = forwardRef((props, ref) => (
  <svg
    ref={ref}
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <path
      d="M14.2222 14.2222H1.77778V1.77778H8V0H1.77778C0.791111 0 0 0.799999 0 1.77778V14.2222C0 15.2 0.791111 16 1.77778 16H14.2222C15.2 16 16 15.2 16 14.2222V7.99999H14.2222V14.2222ZM9.77778 0V1.77778H12.9689L4.23111 10.5155L5.48444 11.7689L14.2222 3.03111V6.22222H16V0H9.77778Z"
      fill="#4F4F4F"
    />
  </svg>
));

OpenInNew.displayName = 'OpenInNew';

export default OpenInNew;

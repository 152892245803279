import React, { useCallback, useMemo, useRef, useEffect, useState } from 'react';
import AccordionCustom from '@beewise/accordion-custom';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { modalController } from 'components/reusables/ModalManager/modalController';
import { MODALS } from 'components/reusables/ModalManager/config';
import {
    deleteRanchById,
    modifyRanchOrders,
    sendSmsWithCoordinates,
    requestRanchApproval,
} from 'components/views/AdminView/actions';
import constants from 'appConstants';
import { RanchType } from '../types';
import RanchInfoColumn from './RanchInfoColumn';
import BeekeeperColumn from './BeekeeperColumn';
import InstallerColumn from './InstallerColumn';

const RanchItem = ({
    data: { ranches, navigate, openRanchId, handleAccordionToggle, setSize, windowWidth },
    index,
    style,
}) => {
    const dispatch = useDispatch();
    const rowRef = useRef();

    const ranch = ranches[index];
    const description = `${ranch.totalAssignedHives} Hives assigned out of ${ranch.totalPlannedHives}`;
    const isOpen = openRanchId === ranch.id;
    const [isApprovalRequestDisabled, setIsApprovalRequestDisabled] = useState(
        ranch?.approvalStatus === constants.RANCH_APPROVAL_STATUSES.REQUESTED ||
            ranch?.approvalStatus === constants.RANCH_APPROVAL_STATUSES.APPROVED
    );

    useEffect(() => {
        setSize(index, rowRef.current.getBoundingClientRect().height);
    }, [isOpen, index, windowWidth, setSize]);

    const handleOpenModal = useCallback(
        () =>
            modalController.set({
                name: MODALS.MANAGE_INSTALLERS_AND_BEEKEEPERS,
                props: {
                    ranch,
                    onConfirm: (data, onClose, onReject) => dispatch(modifyRanchOrders(data, onClose, onReject)),
                },
            }),
        [ranch, dispatch]
    );

    const threeDotMenu = useMemo(
        () => [
            {
                label: 'Send ranch location',
                onClick: () =>
                    modalController.set({
                        name: MODALS.SEND_RANCH_LOCATION,
                        props: {
                            onConfirm: ({ contactName, phoneNumber }) => {
                                const coordinates = ranch.loadingZoneCoordinates
                                    ? `lng: ${ranch.loadingZoneCoordinates.lng}, lat ${ranch.loadingZoneCoordinates.lat}`
                                    : `lng: ${ranch.lng}, lat ${ranch.lat}`;
                                dispatch(sendSmsWithCoordinates({ contactName, phoneNumber, coordinates }));
                            },
                        },
                    }),
            },
            {
                label: isApprovalRequestDisabled ? 'Ask for another layout approval' : 'Ask for layout approval',
                onClick: () => dispatch(requestRanchApproval(ranch.id, () => setIsApprovalRequestDisabled(true))),
                ...(ranch?.approvalStatus
                    ? {
                          tooltip: `Approval is in status: ${
                              ranch?.approvalStatus || constants.RANCH_APPROVAL_STATUSES.REQUESTED
                          }`,
                      }
                    : {}),
            },
            {
                label: 'Open grower dashboard',
                onClick: () => window.open(`/report/${ranch.id}`, '_blank'),
            },
            {
                label: 'Delete ranch',
                onClick: () =>
                    modalController.set({
                        name: MODALS.UNIVERSAL_TEXT_MODAL,
                        props: {
                            onConfirm: () => dispatch(deleteRanchById(ranch.id)),
                            text: 'Are you sure you want to delete this ranch and it’s content? This action cannot be undone.',
                        },
                    }),
            },
        ],
        [isApprovalRequestDisabled, ranch, dispatch]
    );

    return (
        !!ranch && (
            <div style={style}>
                <AccordionCustom
                    key={ranch.id}
                    title={ranch.name}
                    description={description}
                    threeDotMenu={threeDotMenu}
                    isOpen={isOpen}
                    onToggle={() => handleAccordionToggle(ranch.id)}
                    ref={rowRef}
                >
                    <div className="ranches-info-container">
                        <RanchInfoColumn ranch={ranch} navigate={navigate} />
                        <BeekeeperColumn
                            orders={ranch.orders}
                            handleOpenModal={handleOpenModal}
                            isManageDisabled={!ranch?.locations?.length}
                        />
                        <InstallerColumn
                            installers={ranch.installers}
                            handleOpenModal={handleOpenModal}
                            isManageDisabled={!ranch?.locations?.length}
                        />
                    </div>
                </AccordionCustom>
            </div>
        )
    );
};

RanchItem.propTypes = {
    data: PropTypes.shape({
        ranches: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.shape(RanchType)), PropTypes.array]),
        navigate: PropTypes.func.isRequired,
        isOpen: PropTypes.bool,
        handleAccordionToggle: PropTypes.func.isRequired,
        openRanchId: PropTypes.string,
        setSize: PropTypes.func,
        windowWidth: PropTypes.arrayOf(PropTypes.number),
    }).isRequired,
    index: PropTypes.number.isRequired,
    style: PropTypes.shape({}).isRequired,
};

export default RanchItem;

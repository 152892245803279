/* eslint-disable no-unused-vars */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import constants from 'appConstants';
import { convertArea, getUnits, getRatioHeader } from 'utils/isIsraeliRanch';
import Card from 'components/reusables/Card';
import DeliveryStatus from './DeliveryStatus';

const RanchDetails = ({ ranch }) => {
    const totalArea = useMemo(
        () => convertArea(ranch.totalArea, ranch?.country).toFixed(),
        [ranch?.totalArea, ranch?.country]
    );
    const units = useMemo(() => getUnits(ranch.country).area, [ranch?.country]);
    const ratioHeader = useMemo(() => getRatioHeader(ranch?.country), [ranch?.country]);

    const orderedHives = useMemo(
        () =>
            ranch.locations.reduce((acc, location) => {
                acc += location.hivesAmount || constants.DEFAULT_HIVES_AMOUNT;
                return acc;
            }, 0),
        [ranch]
    );

    const placedHives = useMemo(
        () =>
            ranch.locations.reduce((acc, location) => {
                acc += location.placedHivesAmount || 0;
                return acc;
            }, 0),
        [ranch]
    );

    const generalSectionFields = useMemo(
        () => [
            { name: 'Total area', value: `${totalArea} ${units}` },
            { name: 'Crop', value: ranch.crop?.split('_')?.join(' ') },
            ...(ranch.note ? [{ name: 'Notes', value: ranch.note }] : []),
            ...(ranch?.trees?.length
                ? [
                      {
                          name: 'Tree Varieties',
                          value: ranch?.trees.map(item => `${item.name} ${item.percent}%`).join(', '),
                      },
                  ]
                : []),
        ],
        [totalArea, units, ranch.crop, ranch.note, ranch?.trees]
    );

    const pollinationDetailFields = useMemo(
        () => [
            { name: ratioHeader, value: ranch.ratio },
            { name: 'Required number of hives', value: ranch.requiredHivesAmount },
            { name: 'Ordered hives', value: orderedHives },
            { name: 'Placed hives', value: placedHives },
            { name: 'Total hive drops', value: ranch.locations.length },
        ],
        [ratioHeader, ranch?.ratio, orderedHives, ranch.locations.length, placedHives, ranch.requiredHivesAmount]
    );

    const lastOrder = useMemo(
        // eslint-disable-next-line no-unsafe-optional-chaining
        () => ranch?.orders?.[ranch?.orders?.length - 1],
        [ranch?.orders]
    );

    return (
        <div className="right-panel-ranch">
            {lastOrder?.status && lastOrder.status !== constants.ORDER_STATUSES.ORDERED && (
                <DeliveryStatus order={lastOrder} />
            )}
            <Card title="General" items={generalSectionFields} />
            <Card title="Pollination Details" items={pollinationDetailFields} />
            {/* // DEMO comment<div className="divider"/><PollinationSavings ranch={ranch}/> */}
        </div>
    );
};

export default RanchDetails;

RanchDetails.propTypes = {
    ranch: PropTypes.shape(),
};

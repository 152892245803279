import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const ChartBar = forwardRef(({ color = 'currentColor', ...rest }, ref) => (
  <svg
    ref={ref}
    xmlns="http://www.w3.org/2000/svg"
    width="64"
    height="48"
    viewBox="0 0 64 48"
    fill={color}
    {...rest}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M49.6 36H52.4C53.2 36 54 35.2 54 34.4V5.60002C54 4.8 53.2 3.99998 52.4 3.99998H49.6C48.8 3.99998 48 4.8 48 5.60002V34.4C48 35.2 48.8 36 49.6 36ZM25.6 36H28.4C29.2 36 30 35.2 30 34.4V9.60003C30 8.80001 29.2 7.99999 28.4 7.99999H25.6C24.8 7.99999 24 8.80001 24 9.60003V34.4C24 35.2 24.8 36 25.6 36ZM37.6 36H40.4C41.2 36 42 35.2 42 34.4V17.6C42 16.8 41.2 16 40.4 16H37.6C36.8 16 36 16.8 36 17.6V34.4C36 35.2 36.8 36 37.6 36ZM62 42H5.99997V1.99999C5.99997 0.894989 5.10498 0 3.99998 0H1.99999C0.894989 0 0 0.894989 0 1.99999V44C0 46.2088 1.79124 48 4.00001 48H62C63.105 48 64 47.105 64 46V44C64 42.895 63.105 42 62 42L62 42ZM13.6 36H16.4C17.2 36 18 35.2 18 34.4V25.6C18 24.8 17.2 24 16.4 24H13.6C12.8 24 12 24.8 12 25.6V34.4C12 35.2 12.8 36 13.6 36Z"
      fill="#BDBDBD"
    />
  </svg>
));

ChartBar.propTypes = {
  color: PropTypes.string
};

ChartBar.displayName = 'ChartBar';

export default ChartBar;

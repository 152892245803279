import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Input from '@beewise/input';
import Button from '@beewise/button';
import Icon from '@beewise/icon';
import analytics from 'utils/analytics';
import { fetchForgotPassword, fetchSignIn, setForgotPasswordError } from './actions/sign.in.actions';

const SignIn = ({
    fetchSignIn,
    error,
    setForgotPasswordError,
    forgotPasswordError,
    fetchForgotPassword,
    forgotPasswordSent,
}) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const submitSignIn = () => {
        if (email && password) {
            fetchSignIn(email, password);

            analytics.fireGeneralEvent({
                action: 'Sign In',
            });
        }
    };

    const validateEmail = useCallback(() => {
        const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(email);
    }, [email]);

    const toggleForgotPassword = useCallback(() => {
        if (!email || !validateEmail()) {
            setForgotPasswordError('Please enter valid email.');
        } else {
            setForgotPasswordError(null);
            fetchForgotPassword(email);
        }
    }, [email, setForgotPasswordError, fetchForgotPassword, validateEmail]);

    return (
        <div className="signin">
            <div className="signin-container">
                <h2 className="signin-header">{forgotPasswordSent ? 'Reset Password' : 'Log In'}</h2>
                {forgotPasswordSent ? (
                    <div className="forgot-password">
                        <div className="forgot-password-content">
                            <Icon type="check" className="forgot-password-icon" />
                            <div className="forgot-password-margin">We have sent a Reset Password email to </div>
                            <div className="forgot-password-bold">{email}</div>
                            <div className="forgot-password-margin">
                                Follow the directions in the email to reset your password.
                            </div>
                            <div className="forgot-password-tip forgot-password-margin">
                                <div>Haven&#39;t received the email yet?</div>
                                <div>
                                    Please check your spam and promotions folders,
                                    <br />
                                    or{' '}
                                    <button className="forgot-password-resend" onClick={toggleForgotPassword}>
                                        resend
                                    </button>{' '}
                                    the email.
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="signin-form">
                        <Input
                            type="email"
                            label="Email"
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                            error={forgotPasswordError}
                        />
                        <Input
                            type="password"
                            label="Password"
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                            onKeyUp={e => {
                                if (e.keyCode === 13) {
                                    submitSignIn();
                                }
                            }}
                        />
                        <div className="forgot-password-toggler" role="presentation" onClick={toggleForgotPassword}>
                            Forgot Password?
                        </div>
                        <div className="signin-error">{error}</div>
                        <Button className="btn-confirm" onClick={submitSignIn}>
                            Log In
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
};

SignIn.propTypes = {
    fetchSignIn: PropTypes.func.isRequired,
    setForgotPasswordError: PropTypes.func.isRequired,
    fetchForgotPassword: PropTypes.func.isRequired,
    forgotPasswordSent: PropTypes.bool,
    error: PropTypes.string,
    forgotPasswordError: PropTypes.string,
};

export default connect(
    state => ({
        error: state.signin.error,
        forgotPasswordError: state.signin.forgotPasswordError,
        forgotPasswordSent: state.signin.forgotPasswordSent,
    }),
    { fetchSignIn, setForgotPasswordError, fetchForgotPassword }
)(SignIn);

const getElementSum = (frame, element, isHistory = false) =>
  Object.keys(frame).reduce((acc, frameKey) => {
    const lowerCasedKey = frameKey.toLowerCase();
    if (
      lowerCasedKey.includes('side') &&
      frame[frameKey] &&
      ((!isHistory && !lowerCasedKey.includes('previous')) ||
        (isHistory && lowerCasedKey.includes('previous')))
    ) {
      const sideElement = Object.keys(frame[frameKey]).reduce((sideAcc, sideKey) => {
        if (sideKey.includes(element)) {
          return sideAcc + frame[frameKey][sideKey];
        }
        return sideAcc;
      }, 0);
      return acc + sideElement;
    }
    return acc;
  }, 0);

export default {
  getElementSum
};

import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const EmptyMarkerActive = forwardRef(({ color = 'currentColor', size = 24, ...rest }, ref) => (
  <svg
    ref={ref}
    width="30"
    height="42"
    viewBox="0 0 30 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.5833 40.1464C14.2002 39.6991 13.686 39.088 13.0811 38.3428C11.7268 36.6741 9.92275 34.3383 8.12063 31.6673C6.3166 28.9934 4.5269 26.0019 3.19125 23.0205C1.84973 20.0261 1 17.1154 1 14.5833C1 7.07312 7.07312 1 14.5833 1C22.0935 1 28.1667 7.07312 28.1667 14.5833C28.1667 17.1154 27.3169 20.0261 25.9754 23.0205C24.6398 26.0019 22.8501 28.9934 21.046 31.6673C19.2439 34.3383 17.4398 36.6741 16.0855 38.3428C15.4807 39.088 14.9665 39.6991 14.5833 40.1464ZM8.375 14.5833C8.375 18.0106 11.156 20.7917 14.5833 20.7917C18.0106 20.7917 20.7917 18.0106 20.7917 14.5833C20.7917 11.156 18.0106 8.375 14.5833 8.375C11.156 8.375 8.375 11.156 8.375 14.5833Z"
      fill="#222222"
      stroke="white"
      strokeWidth="2"
    />
    <circle cx="14.5833" cy="14.5833" r="10.4167" fill="#222222" />
  </svg>
));

EmptyMarkerActive.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

EmptyMarkerActive.displayName = 'EmptyMarkerActive';

export default EmptyMarkerActive;

import React, { useState, useCallback, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'semantic-ui-react';
import Button from '@beewise/button';

const InputWrapper = forwardRef(({ type = 'text', ...rest }, ref) => {
  const [isPasswordShown, togglePasswordVisibility] = useState(false);
  let currentInputType = type;

  if (type === 'password' && isPasswordShown) {
    currentInputType = 'text';
  }

  const handlePasswordEyeClick = useCallback(
    () => togglePasswordVisibility(!isPasswordShown),
    [isPasswordShown]
  );

  return (
    <>
      {type === 'password' ? (
        <div className="input-password">
          <Input type={currentInputType} fluid {...rest} ref={ref} />
          {type === 'password' && (
            <Button
              className="password-toggler"
              iconClassName="password-toggler-icon"
              iconType={`eye${isPasswordShown ? '-off' : ''}`}
              onClick={handlePasswordEyeClick}
            />
          )}
        </div>
      ) : (
        <Input type={currentInputType} {...rest} ref={ref} />
      )}
    </>
  );
});

InputWrapper.propTypes = {
  type: PropTypes.string
};

export default InputWrapper;

import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const AlertTriangle = forwardRef(({ size = 24, ...rest }, ref) => (
  <svg
    ref={ref}
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    {...rest}
  >
    <path
      d="M10.7661 5C11.5359 3.66667 13.4604 3.66667 14.2302 5L20.7254 16.25C21.4952 17.5833 20.533 19.25 18.9934 19.25H6.00299C4.46338 19.25 3.50113 17.5833 4.27093 16.25L10.7661 5Z"
      fill="#F22A2A"
    />
    <path
      d="M12.5 13.662C12.3037 13.662 12.1355 13.5968 11.9953 13.4665C11.8645 13.3361 11.7804 13.1499 11.743 12.9078L11.014 8.56425C11.0047 8.50838 11 8.43389 11 8.34078C11 7.92179 11.1402 7.5959 11.4206 7.36313C11.7103 7.12104 12.0701 7 12.5 7C12.9393 7 13.2991 7.12104 13.5794 7.36313C13.8598 7.60521 14 7.93575 14 8.35475C14 8.44786 13.9953 8.52235 13.986 8.57821L13.243 12.9078C13.215 13.1499 13.1308 13.3361 12.9907 13.4665C12.8598 13.5968 12.6963 13.662 12.5 13.662ZM12.5 17C12.1075 17 11.7804 16.8696 11.5187 16.6089C11.2664 16.3482 11.1402 16.027 11.1402 15.6453C11.1402 15.2635 11.2664 14.9423 11.5187 14.6816C11.7804 14.4209 12.1075 14.2905 12.5 14.2905C12.8832 14.2905 13.2009 14.4209 13.4533 14.6816C13.715 14.9423 13.8458 15.2635 13.8458 15.6453C13.8458 16.027 13.715 16.3482 13.4533 16.6089C13.2009 16.8696 12.8832 17 12.5 17Z"
      fill="white"
    />
  </svg>
));

AlertTriangle.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

AlertTriangle.displayName = 'AlertTriangle';

export default AlertTriangle;
